application.register("comp_images", class extends Stimulus.Controller {

    connect() {
        if(window.matchMedia("(max-width: 47.9375em)").matches) {
            let self = this,
                elem = self.element,
                nextElm = elem.nextElementSibling;

            if(nextElm !== null && nextElm.classList.contains("comp_slider")) {
                let images = [...elem.querySelectorAll("[data-comp-images-elm]")].reverse();
                if(images[0] !== null) {
                    elem.classList.add("is--merged");

                    let mobileSlider = nextElm.cloneNode(true);
                    mobileSlider.classList.add("only--m");
                    nextElm.classList.add("only--t");

                    let sliderElm = mobileSlider.querySelector("[data-lib-ns]");
                    let dotsElm = mobileSlider.querySelector(".elm_comp_dots");

                    if(sliderElm !== null) {
                        if(dotsElm !== null) {
                            dotsElm.innerHTML = "";
                        }

                        images.forEach((elm, index)=>{
                            let newElm = elm.cloneNode(true);
                            sliderElm.insertAdjacentElement("afterbegin",newElm);
                            if(index === images.length - 1) {
                                elem.insertAdjacentElement("afterend",mobileSlider);
                            }
                        });
                    }
                }
            }
        }
    }
});