let dynamicControllers = ["part_ui_input", "part_ui_select", "part_ui_wsw", "part_cookieconsent", "part_form_cookieconsent"];
let dynamicActions = [[`a[href^="#"]`, "click->lib#anchor"], [`.part_ui_btn, .part_ui_link`, "click->lib#ripple"]];
let locomotive, locomotiveSections = [];

const application = Stimulus.Application.start(document.documentElement, {
    controllerAttribute: "data-controller",
    actionAttribute: "data-action",
    targetAttribute: "data-target"
});

importScript.used = [];
importScript.loaded = [];

if ('serviceWorker' in navigator && location.protocol === "https:") {
    window.addEventListener('load', function () {
        if (!document.documentElement.classList.contains("no-sw")) {
            navigator.serviceWorker.register('/sw.js').catch(function (e) {
                console.error('Error during service worker registration:', e);
            });
        } else {
            navigator.serviceWorker.getRegistrations().then(function (registrations) {
                if (registrations.length > 0) {
                    for (let registration of registrations) {
                        registration.unregister()
                    }
                }
            });
        }
    });
}

function locomotiveChangeYears(includePast) {
    if(document.querySelector("#layout_main").classList.contains("is--generic")) return;

    let elms = [];
    let currentScrollY = 0;
    let date = document.querySelector("#layout_date");
    let dateInner = date.querySelector('[data-target="layout_date.inner"]');
    let nav = document.querySelector("#layout_sidenav");

    if(includePast) {
        elms = [...document.querySelector("#layout_past").querySelectorAll(`[class^="comp_"][data-year]`)]
            .concat([...document.querySelector("#layout_present").querySelectorAll(`[class^="comp_"][data-year]`)])
    }
    else {
        elms = [...document.querySelector("#layout_present").querySelectorAll(`[class^="comp_"][data-year]`)];
    }

    if (elms[0] !== null) {
        locomotiveSections = [];
        elms.forEach((elm)=>{
            locomotiveSections.push({
                elm: elm,
                id: elm.id,
                top: elm.offsetTop - Math.round(dateInner.getBoundingClientRect().height),
                bottom: elm.offsetTop + Math.floor(elm.getBoundingClientRect().height),
                year: elm.dataset.year,
                yearInvert: elm.classList.contains("year--invert")
            });
        });

        function scroll() {
            let currentYear = dateInner.dataset.year;

            for(let i=locomotiveSections.length - 1; i>=0; i--) {
                let section = locomotiveSections[i];
                if(currentScrollY >= section.top) {

                    //change date color
                    if(section.yearInvert) {
                        date.classList.add("text--color-invert");
                    }
                    else {
                        date.classList.remove("text--color-invert");
                    }

                    //update nav
                    if(section.id !== "") {
                        let activeNav = nav.querySelector(".state--active");
                        if(activeNav !== null) {
                            activeNav.classList.remove("state--active");
                        }
                        let currentNav = nav.querySelector(`[href="#${section.id}"]`);
                        if(currentNav !== null) {
                            currentNav.classList.add("state--active");
                        }
                    }
                    //change date value, aneb totalni jebka
                    if(typeof section.year !== "undefined") {
                        if(!date.classList.contains("is--changing")) {
                            if(currentYear !== section.year) {
                                date.classList.add("is--changing");
                                dateInner.dataset.year = section.year;
                                let down = parseInt(section.year) - parseInt(currentYear) > 0;

                                for(let j=0; j<4; j++) {
                                    let currentNumber = currentYear.toString()[j],
                                        newNumber = section.year.toString()[j];
                                    if(currentNumber === newNumber) {
                                        if(j === 3) {
                                            date.classList.remove("is--changing");
                                        }
                                        continue;
                                    }
                                    let currentChild = dateInner.children[j]
                                    if(down) {
                                        currentChild.insertAdjacentHTML("beforeend",`<span>${newNumber}</span>`);
                                        currentChild.style.marginTop = "-1em";
                                    }
                                    else {
                                        currentChild.insertAdjacentHTML("afterbegin",`<span style="margin-top: -1em;">${newNumber}</span>`);
                                        currentChild.style.marginTop = "1em";
                                    }
                                    setTimeout(()=>{
                                        currentChild.style.transition = "none";
                                        if(down) {
                                            currentChild.removeChild(currentChild.children[0]);
                                        }
                                        else {
                                            currentChild.children[0].style.marginTop = null;
                                            currentChild.removeChild(currentChild.children[1]);
                                        }
                                        currentChild.style.marginTop = null;
                                        currentChild.offsetHeight;
                                        currentChild.style.transition = null;
                                        if(j === 3) {
                                            date.classList.remove("is--changing");
                                        }
                                    },300);
                                }
                            }
                        }
                    }

                    break;
                }
            }
        }

        locomotive.off("scroll",throttle((e)=>{},150));
        if(!locomotive.isMobile) {
            locomotive.on("scroll",throttle((e)=>{
                currentScrollY = e.delta.y;
                scroll();
            },150));
        }
    }
}

(function locomotiveScroll() {
    if(!document.documentElement.classList.contains("ie")) {
        locomotive = new LocomotiveScroll({
            el: document.getElementById('layout_scroll'),
            smooth: true,
            getSpeed: true,
            getDirection: true,
            lerp: 0.1
            // smoothMobile: true
        });

        setTimeout(function () {
            locomotive.update();
        },1000);

        locomotiveChangeYears(false);
    }
})();

if (document.body.classList.contains("body--preload")) {
    cssLoaded(function () {
        document.body.classList.remove("body--preload");
        setTimeout(function () {
            document.body.classList.add("body--loaded");
        }, 300);
    });
}

if (typeof WebFontConfig === "undefined" && document.querySelector("[data-loadcss]") !== null) {
    if (typeof document.fonts !== "undefined") {
        document.fonts.ready.then(function () {
            document.documentElement.classList.add("wf-active");
        });

        setTimeout(() => {
            if (!document.documentElement.classList.contains("wf-active")) {
                document.documentElement.classList.add("wf-active");
            }
        }, 500);
    } else {
        document.documentElement.classList.add("wf-active");
    }
}

if (document.createElement('picture').toString().indexOf("HTMLPictureElement") === -1 && !document.documentElement.classList.contains("ie")) {
    importScript(cdnjs.picturefill);
}

if (typeof document.documentElement.style.grid !== 'string') {
    document.documentElement.classList.add("no-grid");
}