application.register("layout_sidenav", class extends Stimulus.Controller {
    connect() {
        let header = this.element,
            currentScrollY = 0,
            latestKnownScrollY = 0,
            ticking = false,
            offset = window.innerHeight;

        function setHeaderState() {
            if (currentScrollY >= offset) {
                header.classList.add("is--visible");
            } else {
                header.classList.remove("is--visible");
            }
            latestKnownScrollY = currentScrollY;
        }

        function update() {
            setHeaderState();
            ticking = false;
        }

        function onScroll() {
            if(!ticking) {
                requestAnimationFrame(update);
                ticking = true;
            }
        }

        bodyLoaded(()=>{
            setTimeout(()=>{
                if(typeof locomotive !== "undefined" && !locomotive.isMobile) {
                    locomotive.on("scroll",(e)=>{
                        currentScrollY = e.delta.y;
                        onScroll();
                    });
                }
                else {
                    window.addEventListener('scroll', ()=>{
                        currentScrollY = window.scrollY;
                        onScroll();
                    }, false);
                }
            },100);
        })
    }

    checkYear(e) {
        let id = e.currentTarget.getAttribute("href");
        let target = document.querySelector(id);
        if(target !== null) {
            let past = target.closest("[data-target=\"layout_past.pastBody\"]")
            if (past !== null && past.classList.contains("is--collapsed")) {
                document.querySelector("#layout_past").layout_past.togglePast();
            }
        }
    }
});