application.register("lib-parallax", class extends Stimulus.Controller {
    connect() {
        let scene = this.element;
        importScript(cdnjs.parallax,()=>{
            let params = null;
            if (window.outerWidth < 600) {
                params = {
                    scalarX: 24,
                    scalarY: 24
                };
            }
            let parallax = new Parallax(scene,params);
        });
    }
});