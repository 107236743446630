application.register("layout_past", class extends Stimulus.Controller {
    get ref() {
        return {
            button: this.targets.find("button"),
            pastBody: this.targets.find("pastBody"),
            toggleLng: this.targets.find("toggleLng")
        }
    }

    connect() {
        this.element[this.identifier] = this;

        window.addEventListener("resize",()=>{
            setTimeout(()=>{
                if(this.ref.pastBody.classList.contains("is--collapsed")) {
                    locomotive.update();
                    locomotiveChangeYears(false);
                }
                else {
                    locomotive.update();
                    locomotiveChangeYears(true);
                }
            },500);
        });
    }

    togglePast(e) {
        let lng = JSON.parse(this.ref.button.dataset.actionLng);

        if(this.ref.pastBody.classList.contains("is--collapsed")) {
            this.ref.pastBody.classList.remove("is--collapsed");
            this.ref.button.classList.add("state--active");
            this.ref.toggleLng.textContent = lng["active"];
            locomotive.update();
            locomotiveChangeYears(true);
        }
        else {
            this.ref.pastBody.classList.add("is--collapsed");
            this.ref.button.classList.remove("state--active");
            this.ref.toggleLng.textContent = lng["inactive"];
            locomotive.update();
            locomotiveChangeYears(false);
        }
    }
});