application.register("layout_header", class extends Stimulus.Controller {
    connect() {
        let header = this.element,
            currentScrollY = 0,
            latestKnownScrollY = 0,
            ticking = false,
            offset = 5;

        function setHeaderState() {
            if (currentScrollY >= offset) {
                header.classList.add("header--not-top");
                if (currentScrollY > latestKnownScrollY){
                    header.classList.remove("header--pinned");
                    header.classList.add("header--unpinned");
                } else {
                    header.classList.remove("header--unpinned");
                    header.classList.add("header--pinned");
                }
            } else {
                header.classList.remove("header--not-top", "header--pinned", "header--unpinned");
            }
            latestKnownScrollY = currentScrollY;
        }

        function update() {
            setHeaderState();
            ticking = false;
        }

        function onScroll() {
            if(!ticking) {
                requestAnimationFrame(update);
                ticking = true;
            }
        }

        bodyLoaded(()=>{
            setTimeout(()=>{
                if(typeof locomotive !== "undefined" && !locomotive.isMobile) {
                    locomotive.on("scroll",(e)=>{
                        currentScrollY = e.delta.y;
                        onScroll();
                    });
                }
                else {
                    window.addEventListener('scroll', ()=>{
                        currentScrollY = window.scrollY;
                        onScroll();
                    }, false);
                }
            },100);
        })
    }

    scrollTop() {
        console.log("baf");
        if(typeof locomotive !== "undefined" && !locomotive.isMobile) {
            locomotive.scrollTo(0,0);
        }
        else {
            window.scrollTo(0, 0);
        }

    }
});